import React, { useEffect } from 'react'
import styled from 'styled-components'

import emptyMap from '../../../static/images/map/emptymap.png'

const StyledSvg = styled.svg`
  .mapitem {
    .mapitem__image--hover {
      opacity: 0;
    }

    &:hover {
      cursor: pointer;
      .mapitem__image {
        opacity: 0;
      }
      .mapitem__image--hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
`

function closeModals(e) {
  if (
    !e.target.classList.contains('modal') &&
    !(e.target.dataset.dismiss === 'modal')
  )
    return

  const modals = document.querySelectorAll('.modal')

  for (let i = 0; i < modals.length; i++) {
    modals[i].classList.remove('show')
    modals[i].setAttribute('aria-hidden', 'true')
    modals[i].setAttribute('style', 'display: none')
  }

  const modalsBackdrops = document.querySelectorAll('.modal-backdrop')

  for (let i = 0; i < modalsBackdrops.length; i++) {
    document.body.removeChild(modalsBackdrops[i])
  }
}

export default function Map(props) {
  useEffect(() => {
    document.querySelector('body').addEventListener('click', closeModals)
    return () => {
      document.querySelector('body').removeEventListener('click', closeModals)
    }
  })
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 6884 2365.7'>
      <defs>
        <filter id='dropshadow' height='130%'>
          <feGaussianBlur in='SourceAlpha' stdDeviation='20' />
          <feOffset dx='0' dy='15' result='offsetblur' />
          <feComponentTransfer>
            <feFuncA type='linear' slope='0.75' />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      {/* <title>VBM Landkarte</title> */}
      <g>
        <g id='BG'>
          <image width='6884' height='2331' href={emptyMap} />
        </g>

        {props.items
          .filter((item) => item.hasContent)
          .map((item, index) => (
            <g
              id={item.name}
              key={index}
              className='mapitem'
              data-toggle='modal'
              data-target={`#modal_${item.name}`}>
              <image
                className='mapitem__image'
                width={item.width}
                height={item.height}
                transform={`translate(${(6884 * item.xPercent) / 100} ${(2331 *
                  item.yPercent) /
                  100})`}
                href={item.src}
              />
              <g data-name='hover'>
                <image
                  className='mapitem__image--hover'
                  width={item.width * 3}
                  height={item.height * 3}
                  transform={`translate(${(6884 * item.xPercent) / 100 -
                    item.width / 3} ${(2331 * item.yPercent) / 100 -
                    item.height / 3}) scale(0.5)`}
                  href={item.src}
                  style={{ filter: 'url(#dropshadow)' }}
                  onTouchEnd={() => {
                    const modalName = `#modal_${item.name}`
                    const modal = document.querySelector(modalName)
                    modal.classList.add('show')
                    modal.setAttribute('style', 'display: block;')
                    const backdrop = document.createElement('div')
                    backdrop.classList.add('modal-backdrop', 'fade', 'show')
                    document.querySelector('body').appendChild(backdrop)
                    document.querySelector('body').classList.add('modal-open')
                  }}
                />
              </g>
            </g>
          ))}
      </g>
    </StyledSvg>
  )
}
