import React from 'react'

import DesignThinkingPDF from '../../../static/downloads/VBM_Roadshow_Design_Thinking.pdf'

export default function Stage2() {
  return (
    <div
      className='container position-relative'
      style={{ top: '15px', marginBottom: '5em' }}>
      <h1 className='font-weight-bold station-1 text-vb-orange header-1'>
        Station 2:
        <br />
        Agiles Training durchlaufen
      </h1>
      <div className='mt-3 flex-column header-2'>
        <strong>Agiles Arbeiten erlebbar gemacht:</strong>
        <span>Was bedeutet der Begriff für eine Bank?</span>
      </div>

      <div className='row mt-5'>
        <div className='col-md-8 col-sm-12'>
          <div className='card h-100 gradient text-white'>
            <div className='card-body'>
              <div
                className='jumbotron my-0 py-0'
                style={{ background: 'none' }}>
                <h1 className='display-4 underline'>
                  Design Thinking in 45 min
                </h1>
                <div className='flex-row flex-center'>
                  <i className='fas fa-bullseye fa-5x mr-5'></i>

                  <p className='lead text-white my-5'>
                    Die sechs Stufen des Design Thinking Prozesses und die dazu
                    nötige Denkweise werden verstanden.
                    <br />
                    Was bedeutet Kundenorientierung in diesem Zusammenhang?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4 col-sm-12 mt-sm-5 mt-md-0 order-sm-last order-md-0'>
          <a
            download='VBM Roadshow – Design Thinking in 45 Minuten.pdf'
            href={DesignThinkingPDF}>
            <div className='card-download p-1'>
              <div className='card-download-image'>
                <img
                  className='img-fluid p-2 bg-white'
                  src={'/images/workshop/design-thinking.png'}
                  alt=''
                />
              </div>
              <div className='card-download-content text-black'>
                <i className='fas fa-download'></i>
                Laden Sie hier die Stanford Design Thinking Übung.
              </div>
              <p className='info-text'>
                Zum Schließen der Datei „nach rechts wischen“.
              </p>
            </div>
          </a>
        </div>

        <div className='col mt-5 mb-md-5'>
          <div className='card erkenntnis'>
            <div className='card-body'>
              <h4 className='card-title text-center display-4 mt-0'>
                <i className='fas fa-lightbulb text-vb-orange'></i>
              </h4>
              <ul
                className='list-group list-group-flush'
                style={{ lineHeight: 2, fontSize: '18px' }}>
                <li className='list-group-item bg-none'>
                  Agiles Arbeiten ist eine eigene Denkweise:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Projekte verlaufen nicht linear von Kick-Off bis Launch
                    </li>
                    <li className='list-group-item bg-none'>
                      Die Realität des Kunden, d.h. Kundenbedürfnisse stehen im
                      Vordergrund
                    </li>
                    <li className='list-group-item bg-none'>
                      Experten-Wissen wird genutzt um beste Lösungen für die
                      Kunden zu entwickeln
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Design Thinking kann vielfältig angewandt werden:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Produktentwicklung
                    </li>
                    <li className='list-group-item bg-none'>Prozess Design</li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Fragen, die Sie sich stellen sollten:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Welche Folgen hat das für die Denkweise einer
                      traditionellen Bank?
                    </li>
                    <li className='list-group-item bg-none'>
                      Was können Sie persönlich in Ihrem eigenen Bereich tun, um
                      den Kunden mehr ins Zentrum zu rücken?
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
