import React from 'react'
import styled from 'styled-components'

const StyledDownloadCard = styled.div`
  .card-download {
    position: relative;
    background-color: #f4f4f4;
    border: #e0e0e0 solid 1px;
    border-radius: 10px;
    border-bottom: var(--vb-orange) solid 4px;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.3);

    &-image {
      position: relative;
      padding: 10px;
      margin-top: -30px;
      img,
      video {
        border-radius: 10px;
        box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.2),
          0 2px 9px 0 rgba(62, 57, 107, 0.2);
        transition: transform 0.3s cubic-bezier(0.52, 0.28, 0.47, 1.15);
      }

      &-single:hover {
        transform: translateY(-10px);
      }
    }

    &:hover {
      .card-download-image > img {
        transform: translateY(-10px);
      }
    }

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      font-size: 1rem;

      i {
        margin-right: 20px;
        font-size: 200%;
      }
    }
  }
`

export default function DownloadCard(props) {
  return (
    <StyledDownloadCard>
      <a
        href={props.href}
        download={props.download}
        target='_blank'
        rel='noopener noreferrer'>
        <div className='card-download p-1'>
          <div className='card-download-image'>
            <img className='img-fluid p-2 bg-white' src={props.image} alt='' />
          </div>
          <div className='card-download-content text-black'>
            <i className={`fas ${props.fasicon}`}></i>
            {props.children}
          </div>
          <p className='info-text'>
            Zum Schließen der Datei „nach rechts wischen“.
          </p>
        </div>
      </a>
    </StyledDownloadCard>
  )
}
