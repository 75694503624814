import React from 'react'
import Header from '../../Header/header'
import '../lounge.scss'

import Lounge4 from '../lounge4'

import schritte from '../schrittedata.js'
import Schritt from '../schritt'
import SEO from '../../seo'

class Schritte extends React.Component {
  render() {
    return (
      <>
        <SEO title='Lounge' />
        <Header siteTitle='Digitaler Zwilling' />
        <div
          className='pb-5'
          style={{
            height: '100%',
            backgroundColor: '#e5eff7'
          }}>
          <Lounge4 />
        </div>
        {schritte.map(schritt => (
          <Schritt key={schritt.id} schritteData={schritt} />
        ))}
      </>
    )
  }
}

export default Schritte
