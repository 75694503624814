import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import DownloadCard from '../DownloadCard'

export default function Lounge5() {
  const pdf = useStaticQuery(graphql`
    {
      file(name: { eq: "VBMDigitalisierungsmatrix" }) {
        publicURL
      }
    }
  `)

  return (
    <div className='container position-relative pb-5' style={{ top: '50px' }}>
      <h1 className='font-weight-bold station-1 text-vb-orange header-1 mb-5'>
        Digitalisierungsmatrix
      </h1>

      <div className='row'>
        <div className='col-12'>
          <DownloadCard
            image={'/images/lounge/digitalisierungsmatrix-poster.jpg'}
            fasicon='fa-download'
            href={pdf.file.publicURL}
            download='Digitalisierungsmatrix.pdf'>
            Laden Sie die Digitalisierungmatrix.
          </DownloadCard>
        </div>
      </div>
    </div>
  )
}
