import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import randomColor from 'randomcolor'
import SpeechBubble from './SpeechBubble'
import DownloadCard from '../DownloadCard'
import Masonry from '../Masonry'

const feedbacks = [
  'Tolle Einblicke in die digitale Welt; Es war verständlich; gut organisiert; kurzweilig - Danke!',
  'Methode "Design Thinking" näher kennengelernt und angewendet, Gelegenheit zum Vorstandsdialog',
  'Super Konzept! Gerne wieder.',
  'Ein wirklich geniales Format! Vielen Dank für Spiel, Spaß und Diskussion. Etwas mehr Input vorab wäre zur Vorbereitung der Diskussionen besser gewesen. Evtl. auch akustische Abtrennung der einzelnen Bereichen. Danke!',
  'praktisch gut',
  'Sehr gute lockere Runde, schön gestaltete Workshops, alle wurden mitgenommen, gute Ergebnisse / Teamleitungen, Überblick über alle Projekte erhalten.',
  'entspannt, lerhreich',
  'fresh, agil, anders',
  'Supercool, innovativ, interessant, bitte in Zukunft Tageslicht! Danke.',
  'Horizonterweiternd!',
  'Es war sehr interessant, auch etwas anstrengend da ich schon seit 4 Uhr am Arbeiten bin. Danke!',
]

export default function Lounge2() {
  const pdf = useStaticQuery(graphql`
    {
      file(name: { eq: "AuswertungVBMWorkshopPDF" }) {
        publicURL
      }
    }
  `)

  return (
    <>
      <div className='container position-relative pb-5' style={{ top: '50px' }}>
        <h1 className='font-weight-bold station-1 text-vb-orange header-1'>
          Feedback Ergebnisse
        </h1>
        <div className='mt-5 flex-column header-2'>
          <strong className='mb-5'>
            Feedback der Teilnehmer – eine zufällige Auswahl
          </strong>
          <div className='row'>
            <div className='col col-sm-12 col-md-8'>
              <Masonry columns={3} gap={25}>
                {feedbacks.map((feedback, index) => {
                  return (
                    <SpeechBubble
                      key={index}
                      color={randomColor({
                        hue: '#0066b3',
                        luminosity: 'bright',
                      })}>
                      {feedback}
                    </SpeechBubble>
                  )
                })}
              </Masonry>
            </div>
            <div className='col col-sm-12 col-md-4'>
              <DownloadCard
                image={'/images/lounge/feedback-poster.jpg'}
                fasicon='fa-excel'
                href={pdf.file.publicURL}
                download='Feedback Workshop.pdf'>
                Laden Sie das gesamte Feedback als PDF-Datei.{pdf.publicURL}
              </DownloadCard>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
