import React from 'react'
import Header from '../../Header/header'
import '../lounge.scss'

import Lounge3 from '../lounge3'
import SEO from '../../seo'

class Teilnehmerinput extends React.Component {
  render() {
    return (
      <>
        <SEO title='Lounge' />
        <Header siteTitle='Digitaler Zwilling' />
        <div
          className='pb-5'
          style={{ height: 'calc(100vh - 56px)', backgroundColor: 'white' }}>
          <Lounge3 />
        </div>
      </>
    )
  }
}

export default Teilnehmerinput
