import React from 'react'

export default function Zukunftsthema({ thema }) {
  const { nameid, name, ziel, team, aktivitäten, schritte } = thema
  return (
    <>
      <div
        className='modal fade'
        id={`modal_${nameid}`}
        tabIndex={-1}
        role='dialog'
        aria-labelledby={`modal_${nameid}Label`}
        aria-hidden='true'
        style={{ width: '100% !important' }}>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12 mb-5'>
                    <div className='card gradient'>
                      <div className='card-body text-white'>
                        <h4 className='card-title'>{name}</h4>
                        <p
                          className='card-text text-white'
                          dangerouslySetInnerHTML={{ __html: ziel }}></p>
                      </div>
                    </div>
                  </div>
                  <div className='col col-sm-12 col-md-4 order-last'>
                    <div className='row'>
                      <div className='col mb-5'>
                        <div className='card shadow future'>
                          <h3 className='card-header text-center'>Team</h3>
                          <div className='card-body'>
                            <ul>
                              {team.map((member, index) => (
                                <li
                                  key={index}
                                  className='text-black'
                                  style={{ fontWeight: 'normal' }}>
                                  {member}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col col-12'>
                        <a
                          href='https://www.volksbank-mittweida.de/zukunftsthemen'
                          target='_blank'
                          className='btn btn-primary btn-block shadow future'
                          rel='noopener noreferrer'>
                          Zur Anmeldung
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='col col-12 col-md-8 p-0'>
                    <div className='col mb-5'>
                      <div className='card shadow future'>
                        <h3 className='card-header text-center'>Aktivitäten</h3>
                        <div className='card-body'>
                          <ul>
                            {aktivitäten.map((aktivität, index) => (
                              <li
                                key={index}
                                className='text-black'
                                style={{ fontWeight: 'normal' }}>
                                {aktivität}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className='col mb-5'>
                      <div className='card shadow future'>
                        <h3 className='card-header text-center'>Schritte</h3>
                        <div className='card-body'>
                          <ul>
                            {schritte.map((schritt, index) => (
                              <li
                                key={index}
                                className='text-black'
                                style={{ fontWeight: 'normal' }}>
                                {schritt}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn bg-vb-blue text-white'
                data-dismiss='modal'>
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
