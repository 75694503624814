import React from 'react'
import Helmet from 'react-helmet'

function Stage3() {
  return (
    <>
      <div
        className='container position-relative'
        style={{ top: '15px', marginBottom: '5em' }}>
        <h1 className='font-weight-bold station-1 text-vb-orange header-1'>
          Station 3:
          <br />
          Die Zukunft erfahren
        </h1>
        <div className='mt-3 flex-column header-2'>
          <strong>Die Zukunft der Volksbank:</strong>
          <span>Was sind die Trends? Was muss man kennen?</span>
        </div>

        <div className='row mt-5'>
          <div className='col-md-8 col-sm-12'>
            <div className='card h-100 gradient text-white'>
              <div className='card-body'>
                <div
                  className='jumbotron my-0 py-0'
                  style={{ background: 'none' }}>
                  <h1 className='display-4 underline'>Lernen mit VR-Brille</h1>
                  <div className='flex-row flex-center'>
                    <i className='fas fa-bullseye fa-5x mr-5'></i>

                    <p className='lead text-white my-5'>
                      Erleben einer neuen Technologie und parallel Lernen über
                      die „Zukunft der Bank“.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 mt-sm-5 mt-md-0 order-sm-last order-md-0'>
            <div className='card-download p-1'>
              <div>
                <div className='card-download-image'>
                  <div className='row'>
                    <div className='col-6 offset-3 mb-3'>
                      <img
                        className='img-fluid card-download-image-single'
                        src={'/videos/genossenschaft-poster.jpg'}
                        alt=''
                        data-toggle='modal'
                        data-target='#modal_genossenschaft'
                      />
                    </div>
                    <div className='col-6'>
                      <img
                        className='img-fluid card-download-image-single'
                        src={'/videos/blockchain-poster.jpg'}
                        alt=''
                        data-toggle='modal'
                        data-target='#modal_blockchain'
                      />
                    </div>
                    <div className='col-6'>
                      <img
                        className='img-fluid card-download-image-single'
                        src={'/videos/fintech-poster.jpg'}
                        alt=''
                        data-toggle='modal'
                        data-target='#modal_fintech'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-download-content text-black'>
                <i className='fas fa-video'></i>
                Klicken Sie auf eins der Bilder, um das entsprechende Video
                amzuschauen.
              </div>
            </div>
          </div>

          <div className='col mt-5 mb-md-5'>
            <div className='card erkenntnis'>
              <div className='card-body'>
                <h4 className='card-title text-center display-4 mt-0'>
                  <i className='fas fa-lightbulb text-vb-orange'></i>
                </h4>
                <ul
                  className='list-group list-group-flush'
                  style={{ lineHeight: 2, fontSize: '18px' }}>
                  <li className='list-group-item bg-none'>
                    Genossenschaftsgedanke der Volksbank:
                    <ul
                      className='list-group list-group-flush'
                      style={{ lineHeight: 2, fontSize: '18px' }}>
                      <li className='list-group-item bg-none'>
                        Er steht im Einklang mit nachhaltigem Handeln
                      </li>
                      <li className='list-group-item bg-none'>
                        Er unterstützt die Grundsätze von digitalem Handeln
                      </li>
                    </ul>
                  </li>
                  <li className='list-group-item bg-none'>
                    FinTechs entstehen als Lösung für ein spezielles
                    Kundenbedürfnis:
                    <ul
                      className='list-group list-group-flush'
                      style={{ lineHeight: 2, fontSize: '18px' }}>
                      <li className='list-group-item bg-none'>
                        Kundenfokus höher
                      </li>
                      <li className='list-group-item bg-none'>
                        Lösungen besser
                      </li>
                      <li className='list-group-item bg-none'>
                        „Banking as a Service“ als Alleinstellungsmerkmal
                      </li>
                    </ul>
                  </li>
                  <li className='list-group-item bg-none'>
                    Entwicklung der Blockchain:
                    <ul
                      className='list-group list-group-flush'
                      style={{ lineHeight: 2, fontSize: '18px' }}>
                      <li className='list-group-item bg-none'>
                        Eine Reaktion auf die Bankenkrise
                      </li>
                      <li className='list-group-item bg-none'>
                        Sie dezentralisiert das Bankenwesen
                      </li>
                      <li className='list-group-item bg-none'>
                        Es gibt viele verschiedene Arten von
                        Blockchain/-software
                      </li>
                    </ul>
                  </li>
                  <li className='list-group-item bg-none'>
                    Die Rolle der Volksbank Mittweida:
                    <ul
                      className='list-group list-group-flush'
                      style={{ lineHeight: 2, fontSize: '18px' }}>
                      <li className='list-group-item bg-none'>
                        Die Region wurde als Blockchain Region auserkoren
                      </li>
                      <li className='list-group-item bg-none'>
                        Es wird versucht die Technologie und deren Nutzung in
                        der Region anzusiedeln
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script>
          {`
          try {
            if($) {
              $(".modal").on("hide.bs.modal", e => {
                const video = e.target.querySelector('video')
                video.pause()
              })
            }
          } catch {}
          `}
        </script>
      </Helmet>
    </>
  )
}

function Stage3Modal({ name, src, modalID, poster }) {
  return (
    <>
      <div
        className='modal fade'
        id={`modal_${modalID}`}
        tabIndex={-1}
        role='dialog'
        aria-labelledby={`modal_${modalID}Label`}
        aria-hidden='true'
        style={{ width: '100% !important' }}>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id={`modal_${modalID}Label`}>
                {name}
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                playsInline
                controls
                autoPlay={false}
                className='img-fluid rounded-lg'
                poster={poster}>
                <source src={src} type='video/mp4' />
              </video>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn bg-vb-blue text-white'
                data-dismiss='modal'>
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Stage3, Stage3Modal }
