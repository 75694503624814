import React from 'react'

export default function Schritt({ schritteData }) {
  const { nameid, name, hinweis, schritte } = schritteData
  return (
    <>
      <div
        className='modal fade'
        id={`modal_${nameid}`}
        tabIndex={-1}
        role='dialog'
        aria-labelledby={`modal_${nameid}Label`}
        aria-hidden='true'
        style={{ width: '100% !important' }}>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12 mb-5'>
                    <div className='card gradient'>
                      <div className='card-body text-white'>
                        <h4 className='card-title text-center'>{name}</h4>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-md-8 p-0'>
                    <div className='col mb-5'>
                      <div className='card shadow future'>
                        <h3 className='card-header text-center'>
                          Nächste Schritte
                        </h3>
                        <div className='card-body'>
                          <p dangerouslySetInnerHTML={{ __html: schritte }}></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-md-4 p-0'>
                    <div className='col mb-5'>
                      <div className='card shadow future bg-vb-blue text-white'>
                        <h3 className='card-header text-center'>Beispiel</h3>
                        <div className='card-body'>
                          <p>{hinweis}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn bg-vb-blue text-white'
                data-dismiss='modal'>
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
