import React, { useState, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import PrivateRoute from '../components/PrivateRoute/privateRoute'
import Landing from '../components/Landing/landing'
import Login from '../components/Login/login'
import Workshop from '../components/Workshop/workshop'
import Lounge from '../components/Lounge/lounge'
import Feedback from '../components/Lounge/menu/feedback'
import Teilnehmerinput from '../components/Lounge/menu/teilnehmerinput'
import Schritte from '../components/Lounge/menu/schritte'
import Digitalisierungsmatrix from '../components/Lounge/menu/digitalisierungsmatrix'
import { UserContext } from '../services/UserContext'

const App = () => {
  const config = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          config {
            auth
          }
        }
      }
    }
  `)

  const [user, setUser] = useState(null)
  const value = useMemo(() => ({ user, setUser }), [user, setUser])

  return (
    <Layout>
      <UserContext.Provider value={value}>
        <Router>
          {/* Keine Authentifizierung */}
          {config.site.siteMetadata.config.auth === 'NONE' && (
            <>
              <Landing default />
              <Workshop path='/app/workshop/' />
              <Lounge path='/app/lounge/' />
              <Lounge path='/app/lounge/landkarte' />
              <Feedback path='/app/lounge/feedback/' />
              <Teilnehmerinput path='/app/lounge/teilnehmerinput/' />
              <Schritte path='/app/lounge/schritte/' />
              <Digitalisierungsmatrix path='/app/lounge/digitalisierungsmatrix/' />
            </>
          )}

          {/* Authentifizierung mit Firebase */}
          {config.site.siteMetadata.config.auth === 'FIREBASE' && (
            <>
              <Login default />
              <PrivateRoute path='/app/home' component={Landing} />
              <PrivateRoute path='/app/workshop/' component={Workshop} />
              <PrivateRoute path='/app/lounge/' component={Lounge} />
              <PrivateRoute path='/app/lounge/landkarte' component={Lounge} />
              <PrivateRoute path='/app/lounge/feedback/' component={Feedback} />
              <PrivateRoute
                path='/app/lounge/teilnehmerinput/'
                component={Teilnehmerinput}
              />
              <PrivateRoute path='/app/lounge/schritte/' component={Schritte} />
              <PrivateRoute
                path='/app/lounge/digitalisierungsmatrix/'
                component={Digitalisierungsmatrix}
              />
            </>
          )}
        </Router>
      </UserContext.Provider>
    </Layout>
  )
}
export default App
