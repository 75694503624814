import React, { useLayoutEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { UserContext } from '../../services/UserContext'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { user } = useContext(UserContext)
  useLayoutEffect(() => {
    if (user === null) {
      navigate('/app/login')
    }
  })

  return <Component {...rest} />
}

export default PrivateRoute
