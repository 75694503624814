export default [
  {
    id: 1,
    nameid: 'bargeldlos',
    name: 'Ansprache zum bargeldlosen Zahlen',
    hinweis: 'Bargeldloses Bezahlen vorantreiben (Bäcker, Frisör, …)',
    schritte:
      'Zukünftig werden Gewerbekunden bis zu einer bestimmten Größenordnung direkt in den Geschäftsstellen betreut. Dafür wird es eine Schulung der jeweiligen Verantwortlichen geben. In diese Schulung werden wir den o.g. Punkt mit einfließen lassen, dieses Thema bei jedem Gewerbetreibenden zu platzieren.',
  },
  {
    id: 2,
    nameid: 'onlinebanking',
    name: 'Mitgliedermehrwerte im Online-Banking',
    hinweis:
      'Mitgliedermehrwertprogramm ins Online Banking aufnehmen (auch für nicht - Mitglieder, damit sie sehen was ihnen entgeht)',
    schritte:
      'Das Team Mitgliedschaft (Luise Liebisch, Silvia Mehring, Ines Dunger, Eva Maric, Evelyn Ebert, Sandra Richter, Henry Zukunft, Anett Kolbeck) hat in einem ersten Schritt kurzfristig auf der Agenda, die Auffindbarkeit der Mitgliedermehrwerte sowie die Information zum Programm für Besucher unserer Homepage zu verbessern. Gern können Sie weitere Anregungen ins Team geben.',
  },
  {
    id: 3,
    nameid: 'kundendigitalisierung',
    name: 'Kunden in die Digitalisierung einbeziehen',
    hinweis:
      'Kunden mehr in die Entwicklung neuer Themen einbeziehen: Wie sieht es der Kunde? Nutzen',
    schritte:
      'Bei unserer VICTOR-Befragung haben wir folgende Fragen aufgenommen, um die Meinung unserer Kunden abzuholen: Welche digitalen Leistungen wünschen Sie sich?',
  },
  {
    id: 4,
    nameid: 'jugendmarkt',
    name: 'Jugendmarkt ausbauen',
    hinweis:
      'Noch mehr direkt an junge Kunden herantreten z.B. in Schulen und Kindergärten',
    schritte:
      'Die Jugendmarktverantwortlichen werden ab Sommer 2020 neben den Schulanfängern wie in den Vorjahren nun neu auch die 5. Klassen besuchen, um auch später präsent zu sein. Die Klassen bekommen eine kleine Spende für die Klassenkasse.',
  },
  {
    id: 5,
    nameid: 'marktzeit',
    name: 'Marktzeit erhöhen',
    hinweis:
      'Zeit für Kunden schaffen, statt sich die Zeit mit Prozessen / Vorgängen (Dauer) zu vertreiben',
    schritte:
      'Weiterentwicklung des SCP-Erfassung in eine marktnahe Sachbearbeitung - Hausmitteilung vom 19.12.2019.<br/>Um den Marktbereichen die Chance zu geben sich verstärkt auf die Arbeit mit unseren Kunden zu konzentrieren und Synergien zu bündeln, werden wir zukünftig die Ausrichtung für die Prozessgestaltung verändern:<br/>Im Fokus stehen dabei einfache und schlanke Prozesse. Da wo möglich, wollen wir so weit wie möglich automatisieren. Im Idealfall One-and-Done.<br/>Durch den Berater werden in den neuen Prozessen nur noch die notwendigsten Eingaben direkt im Vorgang gemacht',
  },
  {
    id: 6,
    nameid: 'filialen',
    name: 'Ausbau Kundenservice/-beratung in den Filialen',
    hinweis:
      'Filialkonzept --> Ansprechpartner zu größeren Themen wie Baufi, Gewerbekd. sollten in größeren Filialen da sein  (schnellere Hilfe für Kunden)',
    schritte:
      'Ab Frühjahr 2020 führen wir für alle Berater in den Filialen den Modernisierungskredit für private Immobilienbesitzer bis 100.000 € Finanzierungssumme ein.<br/>Ebenfalls ist für dieses Jahr vorgesehen eigene Spezialisten für Versicherungsprodukte für das Standargeschäft im Privatkundengeschäft auszubilden. Die Schulungen dazu werden kurzfristig starten.<br/>Weiterhin wird es in den Filialen Spezialisten geben, die neben dem PK-Geschäft auch das Aktivgeschäft bis 100.000 € für Gewerbekunden bedienen.',
  },
  {
    id: 7,
    nameid: 'nutzungonlinebanking',
    name: 'Vereinfachung Nutzung Online-Banking',
    hinweis: 'Handling Online Banking für Kunden',
    schritte:
      'Mit der sukzessiven Einführung der Vertriebsplattform wird der komplette Prozess Online-Banking vereinfacht und für den Kunden intuitiver gestaltet.',
  },
  {
    id: 8,
    nameid: 'scanapp',
    name: 'Einführung Scan App',
    hinweis: 'Statt Photo-Tan- Generator eine App (Kostenersparnis 17,50)',
    schritte:
      'Am 06.02.2020 war die Fiducia im Haus. Frau Mehring hatte Ihre Hinweise an dieser Stelle weitergegeben und direkt bei unserem Ansprechpartner platziert. Leider sind in diesen Bereichen aktuell keine Änderungen in Planung. Wir werden dieses Thema aber immer wieder erneut anfragen.',
  },
  {
    id: 9,
    nameid: 'anmeldungonlinebanking',
    name: 'Vereinfachung Anmeldung Online-Banking',
    hinweis: 'Prozess Online Banking Freischaltung zu kompliziert',
    schritte:
      'Am 06.02.2020 war die Fiducia im Haus. Frau Mehring hatte Ihre Hinweise an dieser Stelle weitergegeben und direkt bei unserem Ansprechpartner platziert. Leider sind in diesen Bereichen aktuell keine Änderungen in Planung. Wir werden dieses Thema aber immer wieder erneut anfragen.',
  },
  {
    id: 10,
    nameid: 'vorgangsanpassungen',
    name: 'Vorgangsänderungen/-anpassungen/-wünsche',
    hinweis: 'Vorgänge an Mitarbeiterwünschen wenn möglich mit anpassen',
    schritte:
      'Für die Prozesse in unserem Haus sind Prozessverantwortliche installiert. Sie stellen die Schnittstelle zwischen den Kollegen/innen, die im Prozess arbeiten und der Prozessorganisation dar. Alle Wünsche zum Vorgang können Sie, die im Prozess aktiv sind, an den Prozessverantwortlichen melden. In den regelmäßigen Prozessrunden wird die Möglichkeit zur Umsetzung besprochen. Ein Feedback zu den von Ihnen eingebrachten Wünschen sollten Sie beim Prozessverantwortlichen abfordern – sofern dies nicht erfolgt ist.',
  },
  {
    id: 11,
    nameid: 'veraltetethemen',
    name: 'Veraltete/falsche Themen auf der Homepage',
    hinweis: 'Münzeinzahler von Website löschen',
    schritte:
      'Finden Sie Inhalte auf unserer Homepage, die nicht mehr passen, melden Sie diese bitte zeitnah an die Abteilung Vertriebsmanagement. Damit kann hier schnell eine Anpassung erfolgen. Vielen Dank.',
  },
  {
    id: 12,
    nameid: 'mobilerarbeitsplatz',
    name: 'Mobiler Arbeitsplatz',
    hinweis: 'fester Arbeitsplatz --> Ausbau mobile Arbeitsplätze',
    schritte:
      'Sehr viele Fragen kamen zum mobilen Arbeitsplatz. Wir haben für unser Haus eine einheitliche Regelung getroffen. Unter bestimmten Voraussetzungen ist das mobile Arbeiten für jeden im Haus grundsätzlich möglich. Die genauen Bestimmungen dazu finden Sie in unserem Leistungskatalog in den Arbeitsanweisungen. Die Umsetzung der darin beschriebenen Vorgehensweise zum flexiblen Arbeitsplatz in der Bank, werden wir in diesem Jahr prüfen und starten.',
  },
  {
    id: 13,
    nameid: 'zukunftswerkstätten',
    name: 'Information Zukunftswerkstätten',
    hinweis: 'Unterschiedlicher Stand zu den Zukunftsthemen',
    schritte:
      'Am 27.01.2020 fand eine Veranstaltung für alle Teilnehmer der Zukunftswerkstätten statt. Hier wurde über die Neuorganisation des Prozesses - vom Input des Zukunftsthemas bis zur Überführung in ein konkretes Projekt bzw. Übergabe in die Linie - informiert. Ziel ist ein klares, strukturiertes Vorgehen und Rahmenbedingungen, ob einen Idee weiter verfolgt, zurückgestellt oder verworfen wird.<br/>In einem gemeinsamen Brainstorming wurde zudem über die zukünftige Kommunikation der Werkstattarbeit diskutiert und Maßnahmen identifiziert. Hierzu werden Sie informiert.',
  },
  {
    id: 14,
    nameid: 'weitereswissen',
    name: 'Weiteres Wissen in Technologien',
    hinweis:
      'KI Übernahme von Prozessschritten ;Abgleichen um Prozesse zu verschlanken/automatisieren',
    schritte:
      'Am 21.01.2020 fand ein Workshop zu Künstlicher Intelligenz mit Teilnehmern der Zukunftswerkstatt KI und Kollegen/innen, die das Thema Robotik/RPA im Haus begleiten sowie einigen Multiplikatoren statt. Der Workshop hatte das Ziel, den Teilnehmern zu vermitteln: Was ist eigentlich KI? / Was kann sie leisten – und was nicht? / Welche Voraussetzungen und Rahmenbedingungen müssen gegeben sein? Sowie gemeinsam zu erarbeiten: Wie lassen sich die Erkenntnisse auf die VB Mittweida übertragen? / Welche möglichen Ansätze und nutzenstiftende Use Cases gibt es bei der VB Mittweida? Im Nachgang werden nun die nächsten Schritte konkret ausgearbeitet.',
  },
  {
    id: 15,
    nameid: 'coaching',
    name: 'Coaching',
    hinweis: 'Persönlichkeitsentwicklung / Coaching',
    schritte:
      'Personalentwicklung: Coaching-Angebot - Hausmitteilung vom 14.10.2019.<br/>Die Gespräche sind persönlich und streng vertraulich und werden nur zwischen Ihnen und dem Coach (Ingrid Schulze) geführt.<br/>Bei Interesse melden Sie sich bitte unter post-personal@vb-mittweida.de. Wir stellen einen Kontakt her. Die Terminabstimmung findet dann individuell mit ihr statt.',
  },
]
