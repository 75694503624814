import React from 'react'
import Header from '../../Header/header'
import '../lounge.scss'

import Lounge2 from '../lounge2'
import SEO from '../../seo'

class Feedback extends React.Component {
  render() {
    return (
      <>
        <SEO title='Digitaler Zwilling' />
        <Header siteTitle='Digitaler Zwilling' />
        <div
          className='pb-5'
          style={{ height: 'calc(100% - 56px)', backgroundColor: '#e5eff7' }}>
          <Lounge2 />
        </div>
      </>
    )
  }
}

export default Feedback
