import firebase from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID
}

let instance = null
export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance
    instance = firebase.initializeApp(config)
    return instance
  }
  return null
}
