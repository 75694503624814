import React from 'react'

export default function Stage4_2() {
  return (
    <div
      className='container position-relative'
      style={{ top: '15px', marginBottom: '5em' }}>
      <h1 className='font-weight-bold station-1 text-vb-orange header-1'>
        Station 4.2:
        <br />
        Aspekte der Digitalisierung erleben
      </h1>
      <div className='mt-3 flex-column header-2'>
        <strong>Zwei Aspekte der Digitalisierung:</strong>
        <span>Wie versteht man K.I.?</span>
      </div>

      <div className='row mt-5'>
        <div className='col-md-8 col-sm-12'>
          <div className='card h-100 gradient text-white'>
            <div className='card-body'>
              <div
                className='jumbotron my-0 py-0'
                style={{ background: 'none' }}>
                <h1 className='display-4 underline'>
                  Künstliche Intelligenz erlebbar machen
                </h1>
                <div className='flex-row flex-center'>
                  <i className='fas fa-bullseye fa-5x mr-5'></i>

                  <p className='lead text-white my-5'>
                    Mit Hilfe von Cozmo wird das Thema K.I. (emotional)
                    zugänglich gemacht. Teilnehmer können K.I. in den Banking
                    Kontext einordnen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4 col-sm-12 mt-sm-5 mt-md-0 order-sm-last order-md-0'>
          <div className='card-download p-1'>
            <div className='card-download-image'>
              <video
                muted
                autoPlay
                playsInline
                loop
                poster='/videos/cozmo-poster.jpg'
                className='img-fluid'>
                <source src='/videos/cozmo.mp4' type='video/mp4' />
              </video>
            </div>
            <div className='card-download-content text-black'>
              <i className='fas fa-robot'></i>
              <code className='text-vb-orange'>Hallo, ich bin Cozmo!</code>
            </div>
          </div>
        </div>

        <div className='col mt-5 mb-md-5'>
          <div className='card erkenntnis'>
            <div className='card-body'>
              <h4 className='card-title text-center display-4 mt-0'>
                <i className='fas fa-lightbulb text-vb-orange'></i>
              </h4>
              <ul
                className='list-group list-group-flush'
                style={{ lineHeight: 2, fontSize: '18px' }}>
                <li className='list-group-item bg-none'>
                  Was ist K.I. heute?
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Meistens handelt es sich um maschinelles Lernen: Geräte
                      wiederholen immer wieder bestimmte Prozesse und werden
                      durch Algorithmen kontinuierlich optimiert.
                    </li>
                    <li className='list-group-item bg-none'>
                      Cozmo nutzt eine Vielzahl von Sensoren, um seine Umgebung
                      wahrzunehmen und daraus zu lernen (ähnlich eines Kindes).
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  K.I. und Programmierung:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      K.I. wird dazu führen, dass bestimmte Prozesse einfacher
                      programmiert werden und sich sukzessiv anpassen können.
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  K.I. und Banking:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      K.I. könnte bestimmte Banking Prozesse flexibler machen
                      und weniger zeitaufwendig, z. B. bei Chatbots oder der
                      Auswertung von Kunden-Datensätzen.
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Einfach ausprobieren:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Roboter Cozmo ist voll mit Technologie. Trotzdem kann man
                      schnell mit ihm umgehen. Digitalisierung bedeutet auch,
                      Dinge einfach mal auszuprobieren und keine Berührungsangst
                      zu haben.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
