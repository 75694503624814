import React from 'react'
import Header from '../Header/header'
import './lounge.scss'

import Lounge1 from './lounge1'

import zukunftsthemen from './zukunftsthemendata.js'
import Zukunftsthema from './zukunftsthema'
import SEO from '../seo'

class Lounge extends React.Component {
  render() {
    return (
      <>
        <SEO title='Lounge' />
        <Header siteTitle='Digitaler Zwilling' />
        <div className='st-container'>
          <input
            type='radio'
            name='radio-set'
            defaultChecked
            id='st-control-1'
          />
          <div className='st-info'>
            <i className='fa fa-map mr-2' aria-hidden='true'></i>
            <i className='fa fa-arrows-alt mr-2' aria-hidden='true'></i>
            Verschieben Sie diese Landkarte, um mehr zu entdecken!
          </div>

          <div className='st-scroll'>
            <section className='st-panel flex-column' id='st-panel-1'>
              <Lounge1 />
            </section>
          </div>
        </div>
        {zukunftsthemen.map((zukunftsthema) => (
          <Zukunftsthema key={zukunftsthema.id} thema={zukunftsthema} />
        ))}
      </>
    )
  }
}

export default Lounge
