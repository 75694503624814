import React from 'react'

import BMC from '../../../static/downloads/BusinessModelCanvas.pdf'

export default function Stage1() {
  return (
    <div
      className='container position-relative'
      style={{ top: '15px', marginBottom: '5em' }}>
      <h1 className='font-weight-bold station-1 text-vb-orange header-1'>
        Station 1:
        <br />
        Den Status quo verändern
      </h1>
      <div className='mt-3 flex-column header-2'>
        <strong>Geschäftsmodell Labor:</strong>
        <span>Wie entwickeln wir neue Geschäftsmodelle?</span>
      </div>

      <div className='row mt-5'>
        <div className='col-md-8 col-sm-12'>
          <div className='card h-100 gradient text-white'>
            <div className='card-body'>
              <div
                className='jumbotron my-0 py-0'
                style={{ background: 'none' }}>
                <h1 className='display-4 underline'>Business Model Canvas</h1>
                <div className='flex-row flex-center'>
                  <i className='fas fa-map-signs fa-5x mr-5'></i>

                  <p className='lead text-white my-5'>
                    Von der eigenen Idee zum Geschäftsmodell. Welche Faktoren
                    müssen beachtet werden, um eine Idee in ein funktionierendes
                    Geschäftsmodell zu verwandeln?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4 col-sm-12 mt-sm-5 mt-md-0 order-sm-last order-md-0'>
          <a download='Business Model Canvas.pdf' href={BMC}>
            <div className='card-download p-1'>
              <div className='card-download-image'>
                <img
                  className='img-fluid'
                  src={'/images/workshop/bmc-no-border.png'}
                  alt=''
                />
              </div>
              <div className='card-download-content text-black'>
                <i className='fas fa-download'></i>
                Laden Sie hier das Business Model Canvas.
              </div>
              <p className='info-text'>
                Zum Schließen der Datei „nach rechts wischen“.
              </p>
            </div>
          </a>
        </div>

        <div className='col mt-5 mb-md-5'>
          <div className='card erkenntnis st-color'>
            <div className='card-body'>
              <h4 className='card-title text-center display-4 mt-0'>
                <i className='fas fa-lightbulb text-vb-orange'></i>
              </h4>
              <ul
                className='list-group list-group-flush'
                style={{ lineHeight: 2, fontSize: '18px' }}>
                <li className='list-group-item bg-none'>
                  Was ist ein Geschäftsmodell?
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Ein Geschäftsmodell beschreibt die Logik, wie ein
                      Unternehmen Werte generiert.
                    </li>
                    <li className='list-group-item bg-none'>
                      Ein Geschäftsmodell umfass viele Faktoren, wie
                      Kundenkanäle, Ressourcen und bestimmte Aktivitäten.
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Geschäftsmodellinnovationen:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Durch neuartige Angebote oder auch durch eine neue Art des
                      Vertriebs, kann das Geschäftsmodell verändert werden.
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Neue Ideen müssen in ein Geschäftsmodell überführt werden:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Eine neue Idee oder eine Technologie allein ist zunächst
                      einmal ohne wirtschaftlichen Wert. Erst durch Überführung
                      einer Idee in ein gutes Geschäftsmodell, kann
                      unternehmerischer Mehrwert generiert werden.
                    </li>
                    <li className='list-group-item bg-none'>
                      Nicht jede gute Idee ist somit auch ein gutes
                      Geschäftsmodell.
                    </li>
                    <li className='list-group-item bg-none'>
                      Idee und Geschäftsmodelle müssen Hand in Hand gehen.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
