import React from 'react'
import Header from '../../Header/header'
import '../lounge.scss'

import Lounge5 from '../lounge5'

import SEO from '../../seo'

class Digitalisierungsmatrix extends React.Component {
  render() {
    return (
      <>
        <SEO title='Digitaler Zwilling' />
        <Header siteTitle='Digitaler Zwilling' />
        <Lounge5 />
      </>
    )
  }
}

export default Digitalisierungsmatrix
