import React from 'react'

import wordcloud from '../../../static/images/lounge/wordcloud.jpg'

export default function Lounge3() {
  return (
    <div className='container position-relative' style={{ top: '50px' }}>
      <h1 className='font-weight-bold station-1 text-vb-orange header-1'>
        Mentimeter Ergebnisse
      </h1>
      <div className='mt-5 flex-column header-2'>
        <strong className='mb-0'>
          Welche Stärken haben wir? Was wappnet uns für die Zukunft?
        </strong>
      </div>

      <img src={wordcloud} alt='' className='img-fluid' />
    </div>
  )
}
