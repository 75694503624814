import React from 'react'
import styled from 'styled-components'

import abschlussdokument from '../../../static/downloads/Abschlussdokument.pdf'

const StyledGrid = styled.div`
  display: grid;
  /* grid-template-columns: 100%; */
  width: 100%;
  padding-bottom: 4em;
  grid-template-areas:
    'sichtung sichtung sichtung sichtung sichtung sichtung'
    'kunden kunden prozesse prozesse mitarbeiter mitarbeiter'
    'umsetzung umsetzung umsetzung umsetzung umsetzung umsetzung'
    's1 s2 . . s7 s8'
    's3 s4 . . . s9'
    's5 s6 . . . .'
    'information information information information information information'
    's10 s11 s13 s14 s15 .'
    's12 . . . . .'
    'vorgehensweise vorgehensweise vorgehensweise vorgehensweise vorgehensweise vorgehensweise'
    'abschluss abschluss abschluss abschluss abschluss abschluss';

  @media (max-width: 1024px) {
    grid-template-areas:
      'sichtung sichtung'
      'umsetzung umsetzung'
      'kunden kunden'
      's1 s2'
      's3 s4'
      's5 s6'
      'mitarbeiter mitarbeiter'
      's7 s8'
      '. s9'
      'information information'
      'kunden2 kunden2'
      's10 s11'
      's12 .'
      'prozesse2 prozesse2'
      's13 s14'
      'mitarbeiter2 mitarbeiter2'
      's15 .'
      'vorgehensweise vorgehensweise'
      'abschluss abschluss';

    .prozesse {
      display: none;
    }

    .subheader {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }

  .kunden2 {
    grid-area: kunden2;
  }
  .prozesse2 {
    grid-area: prozesse2;
  }
  .mitarbeiter2 {
    grid-area: mitarbeiter2;
  }

  @media (min-width: 1025px) {
    .kunden2,
    .prozesse2,
    .mitarbeiter2 {
      display: none;
    }
  }

  .header {
    text-align: center;
    background-color: var(--vb-orange);
    color: white;
    font-size: 1.2rem;
    padding: 0.2rem;
    margin: 1rem 0;
    font-weight: bold;
  }

  .subheader {
    margin: auto 1rem;
    background-color: var(--vb-blue);
    background-color: #00477d;
    font-weight: normal;
  }

  .schwerpunkt {
    display: flex;
    margin: 1rem;
    padding: 0.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: var(--vb-blue);
    color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2),
      0 5px 10px -3px rgba(0, 0, 0, 0.4);

    &:hover {
      cursor: pointer;
      background-color: #00477d;
    }
  }

  .abschluss {
    font-weight: bold;
  }

  .sichtung {
    grid-area: sichtung;
  }
  .umsetzung {
    grid-area: umsetzung;
  }
  .information {
    grid-area: information;
  }
  .vorgehensweise {
    grid-area: vorgehensweise;
  }
  .abschluss {
    grid-area: abschluss;
  }

  .kunden {
    grid-area: kunden;
  }
  .prozesse {
    grid-area: prozesse;
  }
  .mitarbeiter {
    grid-area: mitarbeiter;
  }

  .s1 {
    grid-area: s1;
  }
  .s2 {
    grid-area: s2;
  }
  .s3 {
    grid-area: s3;
  }
  .s4 {
    grid-area: s4;
  }
  .s5 {
    grid-area: s5;
  }
  .s6 {
    grid-area: s6;
  }
  .s7 {
    grid-area: s7;
  }
  .s8 {
    grid-area: s8;
  }
  .s9 {
    grid-area: s9;
  }
  .s10 {
    grid-area: s10;
  }
  .s11 {
    grid-area: s11;
  }
  .s12 {
    grid-area: s12;
  }
  .s13 {
    grid-area: s13;
  }
  .s14 {
    grid-area: s14;
  }
  .s15 {
    grid-area: s15;
  }
`

export default function Lounge4() {
  return (
    <div className='container position-relative pb-5' style={{ top: '50px' }}>
      <h1 className='font-weight-bold station-1 text-vb-orange header-1 mb-5'>
        Nächste Schritte
      </h1>

      <StyledGrid>
        <div className='header sichtung'>
          Sichtung und Zuordnung ALLER Rückmeldungen
        </div>
        <div className='header umsetzung'>In Umsetzung:</div>
        <div className='header information'>Information:</div>
        <div className='header vorgehensweise'>Weitere Vorgehensweise:</div>

        <div className='header subheader kunden'>Kundengeschäft</div>
        <div className='header subheader prozesse'>Prozesse/IT</div>
        <div className='header subheader mitarbeiter'>
          Mitarbeiter/Kommunikation
        </div>
        <div className='header subheader kunden2'>Kundengeschäft</div>
        <div className='header subheader prozesse2'>Prozesse/IT</div>
        <div className='header subheader mitarbeiter2'>
          Mitarbeiter/Kommunikation
        </div>

        <div
          className='schwerpunkt s1'
          data-toggle='modal'
          data-target='#modal_bargeldlos'>
          Ansprache zum bargeldlosen Bezahlen
        </div>
        <div
          className='schwerpunkt s2'
          data-toggle='modal'
          data-target='#modal_onlinebanking'>
          Mitgliedermehrwerte im Online-Banking
        </div>
        <div
          className='schwerpunkt s3'
          data-toggle='modal'
          data-target='#modal_kundendigitalisierung'>
          Kunden in die Digitalisierung einbeziehen
        </div>
        <div
          className='schwerpunkt s4'
          data-toggle='modal'
          data-target='#modal_jugendmarkt'>
          Jugendmarkt ausbauen
        </div>
        <div
          className='schwerpunkt s5'
          data-toggle='modal'
          data-target='#modal_marktzeit'>
          Marktzeit erhöhen
        </div>
        <div
          className='schwerpunkt s6'
          data-toggle='modal'
          data-target='#modal_filialen'>
          Ausbau Kundenservice/-beratung in den Filialen
        </div>

        <div
          className='schwerpunkt s7'
          data-toggle='modal'
          data-target='#modal_mobilerarbeitsplatz'>
          Mobiler Arbeitsplatz
        </div>
        <div
          className='schwerpunkt s8'
          data-toggle='modal'
          data-target='#modal_zukunftswerkstätten'>
          Information Zukunftswerkstätten
        </div>
        <div
          className='schwerpunkt s9'
          data-toggle='modal'
          data-target='#modal_weitereswissen'>
          Weiteres Wissen in Technologien
        </div>

        <div
          className='schwerpunkt s10'
          data-toggle='modal'
          data-target='#modal_nutzungonlinebanking'>
          Vereinfachung Nutzung Online-Banking
        </div>
        <div
          className='schwerpunkt s11'
          data-toggle='modal'
          data-target='#modal_scanapp'>
          Einführung Scan-App
        </div>
        <div
          className='schwerpunkt s12'
          data-toggle='modal'
          data-target='#modal_anmeldungonlinebanking'>
          Vereinfachung Anmeldung Online-Banking
        </div>

        <div
          className='schwerpunkt s13'
          data-toggle='modal'
          data-target='#modal_vorgangsanpassungen'>
          Vorgangsänderungen/-anpassungen/-wünsche
        </div>
        <div
          className='schwerpunkt s14'
          data-toggle='modal'
          data-target='#modal_veraltetethemen'>
          Veraltete/falsche Themen auf der Homepage
        </div>

        <div
          className='schwerpunkt s15'
          data-toggle='modal'
          data-target='#modal_coaching'>
          Coaching
        </div>

        <div className='abschluss'>
          Wir werden alle Hinweise zur Sichtung, Bewertung und Umsetzungsprüfung
          an die betroffenen Bereiche weiterleiten. Die Rückmeldungen werden an
          dieser Stelle veröffentlicht. Wir möchten Sie weiterhin herzlich
          einladen, das was Ihnen am Herzen liegt, gern nochmal an uns mit
          konkreten Bespielen heranzutragen.
        </div>
      </StyledGrid>

      <a
        href={abschlussdokument}
        download='Abschlussdokument.pdf'
        className='btn btn-primary btn-block bg-vb-orange border-0 mb-5'
        style={{ fontSize: '1.4rem', borderRadius: '10px' }}>
        <i className='fas fa-download mr-3'></i>
        Laden Sie das gesamte Abschlussdokument
        <p className='info-text-button'>
          Zum Schließen der Datei „nach rechts wischen“.
        </p>
      </a>
    </div>
  )
}
