import React from 'react'
import { Link } from 'gatsby'
import SEO from '../seo'

import Header from '../Header/header'
import './landing.scss'

export default function Landing() {
  function hoverEnter(e) {
    e.preventDefault()
    e.target.classList.add('hover-item')
  }
  function hoverLeave(e) {
    e.preventDefault()
    e.target.classList.remove('hover-item')
  }

  return (
    <>
      <SEO title='Startseite' />
      <Header siteTitle='Digitaler Zwilling' />
      <div className='container mt-4'>
        <h1 className='text-center mb-4 display-3 font-weight-bold'>
          Digitaler Zwilling
        </h1>
        <div className='row'>
          <div className='col col-12 mb-3'>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              playsInline
              controls
              poster={'/videos/trailer-poster.jpg'}
              className='img-fluid rounded-lg'>
              <source src={'/videos/trailer.mp4'} type='video/mp4' />
            </video>
          </div>
          <div className='col col-12 mb-3'>
            <div className='row'>
              <div className='col col-12 col-md-6 mb-5'>
                <Link to='/app/workshop'>
                  <div
                    className='card bg-vb-orange p-3 text-white shadow h-100'
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                    role='none'>
                    <div>
                      <h2 className='card-title text-center'>Workshop</h2>
                      <p className='card-text text-center mb-5'>
                        Lassen Sie den Workshop Revue passieren.
                      </p>

                      <div className='row'>
                        <div className='col col-12 lounge-item mb-5'>
                          <i className='fa fa-lightbulb' aria-hidden='true'></i>
                          Geschäftsmodelle
                        </div>
                        <div className='col col-12 col-md-6 lounge-item mb-5'>
                          <i
                            className='fa fa-fast-forward'
                            aria-hidden='true'></i>
                          Agiles Training
                        </div>
                        <div className='col col-12 col-md-6 lounge-item mb-5'>
                          <i
                            className='fa fa-calendar-check'
                            aria-hidden='true'></i>
                          Zukunft
                        </div>
                        <div className='col col-12 col-md-6 lounge-item mb-5 mb-md-0'>
                          <i
                            className='fa fa-bezier-curve'
                            aria-hidden='true'></i>
                          Blockchain
                        </div>
                        <div className='col col-12 col-md-6 lounge-item mb-0'>
                          <i className='fa fa-robot' aria-hidden='true'></i>
                          Künstliche Intelligenz
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col col-md-6'>
                <div className='row'>
                  <div className='col col-12'>
                    <Link to='/app/lounge/landkarte'>
                      <div
                        className='card bg-vb-blue mb-4 mb-md-5 p-3 p-md-4 text-white shadow'
                        onMouseEnter={hoverEnter}
                        onMouseLeave={hoverLeave}
                        role='none'>
                        <div className='lounge-item'>
                          <i className='fa fa-map' aria-hidden='true'></i>
                          Landkarte
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className='col col-12 col-md-6'>
                    <Link to='/app/lounge/feedback'>
                      <div
                        className='card bg-vb-blue mb-4 mb-md-5 p-3 p-md-4 text-white shadow'
                        onMouseEnter={hoverEnter}
                        onMouseLeave={hoverLeave}
                        role='none'>
                        <div className='lounge-item'>
                          <i className='fa fa-comments' aria-hidden='true'></i>
                          Feedback
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className='col col-12 col-md-6'>
                    <Link to='/app/lounge/teilnehmerinput'>
                      <div
                        className='card bg-vb-blue mb-4 mb-md-5 p-3 p-md-4 text-white shadow'
                        onMouseEnter={hoverEnter}
                        onMouseLeave={hoverLeave}
                        role='none'>
                        <div className='lounge-item'>
                          <i className='fa fa-poll' aria-hidden='true'></i>
                          Teilnehmerinput
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className='col col-12 col-md-6'>
                    <Link to='/app/lounge/schritte'>
                      <div
                        className='card bg-vb-blue mb-4 mb-md-5 p-3 p-md-4 text-white shadow'
                        onMouseEnter={hoverEnter}
                        onMouseLeave={hoverLeave}
                        role='none'>
                        <div className='lounge-item'>
                          <i className='fa fa-road' aria-hidden='true'></i>
                          Nächste Schritte
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className='col col-12 col-md-6'>
                    <Link to='/app/lounge/digitalisierungsmatrix'>
                      <div
                        className='card bg-vb-blue mb-4 mb-md-5 p-3 p-md-4 text-white shadow'
                        onMouseEnter={hoverEnter}
                        onMouseLeave={hoverLeave}
                        role='none'>
                        <div className='lounge-item'>
                          <i className='fa fa-th' aria-hidden='true'></i>
                          Digitalisierungsmatrix
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
