import React from 'react'

import '../layout/bootstrap.min.css'
import '../layout/style.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main>{children}</main>
      </div>
    </>
  )
}

export default Layout
