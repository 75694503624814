import React from "react"

export default function Masonry(props) {
  const columnWrapper = {}
  const result = []

  for (let i = 0; i < props.columns; i++) {
    columnWrapper[`column${i}`] = []
  }

  for (let i = 0; i < props.children.length; i++) {
    const columnIndex = i % props.columns
    columnWrapper[`column${columnIndex}`].push(
      <div key={i} style={{ marginBottom: `${props.gap}px` }}>
        {props.children[i]}
      </div>
    )
  }

  for (let i = 0; i < props.columns; i++) {
    result.push(
      <div
        key={i}
        style={{ marginLeft: `${i > 0 ? props.gap : 0}px`, flex: 1 }}
      >
        {columnWrapper[`column${i}`]}
      </div>
    )
  }

  return <div style={{ display: "flex" }}>{result}</div>
}
