// import { Link } from "gatsby"
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'

import './header.scss'
import { UserContext } from '../../services/UserContext'

const Header = ({ siteTitle }) => {
  const config = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          config {
            auth
          }
        }
      }
    }
  `)

  const { user, setUser } = useContext(UserContext)

  return (
    <header>
      <nav
        className='navbar navbar-expand-lg navbar-light bg-light header-nav'
        style={{ zIndex: 1000 }}>
        <Link className='navbar-brand' to='/app/home'>
          Digitaler Zwilling
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <Link className='nav-link' to='/app/workshop'>
                Workshop
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/app/lounge/landkarte'>
                Landkarte
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/app/lounge/feedback'>
                Feedback
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/app/lounge/teilnehmerinput'>
                Teilnehmerinput
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/app/lounge/schritte'>
                Nächste Schritte
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link'
                to='/app/lounge/digitalisierungsmatrix'>
                Digitalisierungsmatrix
              </Link>
            </li>
          </ul>
          {config.site.siteMetadata.config.auth === 'FIREBASE' && (
            <button
              className='btn text-white'
              title='Ausloggen'
              onClick={e => {
                setUser(null)
                console.log(user)
                navigate('/app/login')
              }}>
              <i className='fas fa-sign-out-alt mr-1'></i>Ausloggen
            </button>
          )}
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
