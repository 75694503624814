import React from 'react'
import styled from 'styled-components'

const StyledSpeechBubble = styled.div`
  background: ${(props) => props.color || '#ff6600'};
  font-family: 'Ubuntu';
  -webkit-border-radius: 4px;
  border-radius: 10px;
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0 auto 40px;
  max-width: 400px;
  padding: 15px;
  position: relative;
  color: white;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.5);

  p {
    margin: 0 0 10px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  &::after {
    border-left: 20px solid transparent;
    border-top: 20px solid ${(props) => props.color || '#ff6600'};
    bottom: -20px;
    content: '';
    position: absolute;
    right: 20px;
  }
`
export default function SpeechBubble(props) {
  return (
    <StyledSpeechBubble color={props.color}>
      {props.children}
    </StyledSpeechBubble>
  )
}
