import React, { useState, useEffect } from 'react'
import { MapInteractionCSS } from 'react-map-interaction'

import Map from './Map'

import './lounge.scss'
import './lounge1.scss'

import mapItems from './mapItems.json'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowDimensions
}

export default function Lounge1() {
  const [translation, setTranslation] = useState({ x: 0, y: 0 })
  const [bounds, setBounds] = useState({})
  const { width, height } = useWindowDimensions()
  const ratio = 2.893125
  const heightRatio = ((height - 90) * ratio) / width
  const [scale, setScale] = useState(heightRatio)

  return (
    <>
      <MapInteractionCSS
        scale={scale}
        translation={translation}
        onChange={({ scale, translation }) => {
          setBounds({
            xMin: -width * scale + width,
            xMax: 0,
            yMin: height - 90 - (width * scale) / ratio,
            yMax: 0,
          })
          setScale(scale)
          setTranslation(translation)
        }}
        showControls={true}
        minScale={heightRatio}
        maxScale={20}
        translationBounds={bounds}>
        <Map items={mapItems} />
      </MapInteractionCSS>
    </>
  )
}
