import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import './login.scss'
import useFirebase from '../../services/useFirebase'
import { UserContext } from '../../services/UserContext'

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const firebase = useFirebase()

  const { user, setUser } = useContext(UserContext)

  const handleSubmit = (event) => {
    event.preventDefault()
    // handleLogin(this.state)

    if (firebase) {
      firebase
        .auth()
        .signInWithEmailAndPassword(username, password)
        .then((response) => {
          setUser(response.user)
          console.log(user)
          navigate('/app/home')
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  return (
    <>
      <div className='login-wrapper flex-column flex-center'>
        <div
          className='card flex-column flex-center p-5 shadow'
          style={{
            position: 'absolute',
            top: '10%',
            justifyContent: 'auto',
          }}>
          <img
            src={'/images/LogoVBMittweida.png'}
            className='img-fluid mb-5'
            alt=''
          />
          <h1 className='mb-5'>Digitaler Zwilling</h1>
          <form
            method='post'
            onSubmit={(event) => {
              handleSubmit(event)
              // navigate(`/app`)
            }}
            className='flex-column'>
            <div className='form-group'>
              <label htmlFor='username' className='mr-3'>
                Benutzername:
              </label>
              <input
                type='text'
                name='username'
                id='username'
                className='form-control'
                onChange={(event) => {
                  setUsername(event.target.value)
                }}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='password' className='mr-3'>
                Passwort:
              </label>
              <input
                type='password'
                name='password'
                id='password'
                className='form-control'
                onChange={(event) => {
                  setPassword(event.target.value)
                }}
              />
            </div>
            <button type='submit' className='btn btn-primary btn-block'>
              Anmelden
            </button>
          </form>
        </div>
      </div>
    </>
  )
}
