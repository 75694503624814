export default [
  {
    id: 1,
    nameid: 'landwirtschaft',
    name: 'Digitalisierung in der Landwirtschaft',
    ziel:
      'Stärkung unserer Rolle als Bank in der Landwirtschaft. Überprüfung neuer Schnittstellen und Abläufe. Aufbau von Netzwerk und Kooperationen. Beobachtungen von Innovationen und Veränderungen. Bedarfserkennung unserer Kunden.',
    team: [
      'Torsten Wein',
      'Steffen Uhlig',
      'Thomas Jope',
      'Sonja Hahn-Tomer',
      'Eva Maric',
    ],
    aktivitäten: [
      'Ideensammlung/Brainstorming/Recherche',
      'Netzwerkarbeit (Digital Farming Conference Berlin, Simul+, Seedhouse)',
      'Überprüfung Produktpalette Aktiv Landwirtschaft und grundlegende Überarbeitung Kondtionen Saisonkredite',
      'Landwirtschaftsforum',
      '01/2020 Neuausrichtung Werkstatt',
    ],
    schritte: [
      'Kundenbefragungen um Handlungsfelder zu ergründen + Auswertung und Vertiefung',
      'Vernetzung mit Werkbank32 und WIR!',
      'Start Einreicherstrecke für Vermittler',
    ],
  },
  {
    id: 2,
    nameid: 'robotik',
    name: 'Robotik / Künstliche Intelligenz',
    ziel:
      'Wissensaufbau zum Thema Robotik/KI mit Fokus auf interne Anwendungen, Blick in die Branche und zu unseren Kunden, im Sinne von „cross-industry“, Abgrenzung der Begriffe, Anwendungsbereiche identifizieren.',
    team: [
      'Steffen Weber',
      'Michael Wiesner',
      'Florian Uhl',
      'Steffen Ott',
      'Raymond Uhlig',
      'Maik Wagner',
    ],
    aktivitäten: [
      'Erste Indikationen für den Einsatz von Robotik wurden erarbeitet',
      'Netzwerkarbeit (Hochschule MW - Robotik Labor, Robotex, Fraunhofer Roadshow, PSD Bank Chemnitz, Prodirect Finance)',
      'Teilnahme an Pilotierung von Botto (Fiducia)',
      'Workshop mit KPMG (Identifaktion von Einsatzmöglichkeiten)',
      'Erstes RPA-Projekt (Enderwerber-Kaufverträge in Baurägerverwaltung)',
      'BootCamp mit Synpulse (Erstellung erster „Bots“)',
    ],
    schritte: [
      'Überführung erster Bots in den Echtbetrieb',
      'Implementierung von RPA in die Organisation',
      'Evaluierung weiterer Anwendungsfälle',
      'Aufbau einer OCR zum Erkennen und Auslesen von Dokumenten Ermittlung von Anwendungsfällen für KI und Smart Data',
      'Ethische Fragestelungen / Juristische Fragestellungen / Aufsichtsrecht',
    ],
  },
  {
    id: 3,
    nameid: 'esports',
    name: 'E-Sports',
    ziel:
      'Ziel ist es, die Innovations- und Wirtschaftskraft in der Region sichtbar zu machen und zu stärken.',
    team: [
      'Lisa Hagelmoser',
      'Jonny Kreisig',
      'Rico Schlegel',
      'Peggy Schemschat',
      'Mike Rudolph',
      'Anja Leißring',
    ],
    aktivitäten: [
      'Ideensammlung/Brainstorming/Recherche',
      'Teilnahme Dream Hack Leipzig',
      'Kontakt mit Hochschule Mittweida geknüpft',
    ],
    schritte: [
      'Vertiefende Recherche und Entscheidung zum Bau einer Gamer-Lounge',
      'Prüfung zur Aufsetzung einer eigenen Veranstaltung',
      'Evaluierung Fördermöglichkeiten Bund',
      'Evaluierung der Möglichkeit als Werbepartner/Sponsor aufzutreten',
    ],
  },
  {
    id: 4,
    nameid: 'nachhaltigkeit',
    name: 'Nachhaltigkeit',
    ziel:
      'Als Volksbank Mittweida eG möchten wir uns ernsthaft und strukturiert mit dem Thema Nachhaltigkeit auseinandersetzen und einen wesentlichen Beitrag zu einem Nachhaltigen Miteinaner entwickeln.',
    team: [
      'Kathrin Droste',
      'Aneta Grund',
      'Cindy Konkol',
      'Eva Maric',
      'Jana Rösch',
      'Katharina Trautschold',
      'Sonja Hahn-Tomer',
    ],
    aktivitäten: ['Auftaktveranstaltung', 'Erarbeitung Roadmap'],
    schritte: [
      'Auftragsklärung und Zielstellung formulieren',
      'Einbindung unserer Kunden über VICTOR-Befragung',
    ],
  },
  {
    id: 5,
    nameid: 'geschaeftsmodell',
    name: 'Membership-Geschäftsmodell',
    ziel: `Aufbau und Etablierung eines Membership-Geschäftsmodells:<br>
    <ol>  
      <li>Aufbau für Werkbank</li>
      <li>Aufbau für Bank</li>
      <li>Aufbau Geschäftsmodell für Vertrieb</li>
      </ol>`,
    team: [
      'Steffen Uhlig',
      'Lisa Hagelmoser',
      'Florian Uhl',
      'Isabel Meinunger',
      'Dagmar Reißig',
      'Thomas Tauchert',
      'Luise Liebisch',
    ],
    aktivitäten: [
      'Ideensammlung/Brainstorming/Recherche',
      'Interviews mit potentiellen Kunden geführt',
    ],
    schritte: [
      'Auftaktveranstaltung',
      'Erarbeitung Roadmap',
      'Erarbeitung erster konkreter Services und Verprobung im Kundendialog',
      'Weiterentwicklung des Membership-Modells und erste Aufsetzung für die Werkbank32',
    ],
  },
  {
    id: 6,
    nameid: 'werkbank',
    name: 'Werkbank32',
    ziel: `Sichtbarkeit, Eröffnungswoche, Konzept Inneneinrichtung/Gestaltung<br>
      Die Zukunftswerkstatt erstellt für die Werkbank32 ein Einrichtungs- und Gestaltungskonzept der Gebäude und soll sich hauptsächlich mit der Frage „Wie erreichen wie Sichtbarkeit bei all unseren Nutzergruppen?“ beschäftigen. Weiterhin hat sich die Werkstatt die Veranstaltung einer erfolgreichen Eröffnungswoche zum Ziel gesetzt.`,
    team: [
      'Sonja Hahn-Tomer',
      'Sophie Böttger',
      'Aneta Grund',
      'Daniela Gärtner',
      'Sindy Horn',
      'Nadja Keßler',
      'Peggy Schemschat',
      'Thomas Sigl',
      'Raymond Uhlig',
      'Sabrina Vogel',
      'Thomas Otto',
    ],
    aktivitäten: [
      'Auswahl Inneneinrichtung für Villa ist erfolgt',
      'Ideensammlung zum Thema Sichtbarkeit',
      'Ideensammlung zum Thema Eröffnungswoche',
    ],
    schritte: [
      'Planung „Digitale Eröffnungswoche“',
      'Erstellung eines Digitalen Rundgangs durch die Werkbank32',
      'Auswahl Inneneinrichtung für die Wäscherei',
      'Konkretisierung und Finalisierung der Sichtbarkeit der Werkbank32',
    ],
  },
]
