import React from 'react'
import Header from '../Header/header'
import './workshop.scss'

import Stage1 from './stage1'
import Stage2 from './stage2'
import { Stage3, Stage3Modal } from './stage3'
import Stage41 from './stage4_1'
import Stage42 from './stage4_2'
import SEO from '../seo'

class Workshop extends React.Component {
  render() {
    return (
      <>
        <SEO title='Workshop' />

        <Header siteTitle='Digitaler Zwilling' />

        <div className='st-container'>
          <input
            type='radio'
            name='radio-set'
            defaultChecked
            id='st-control-1'
          />
          <a href='#st-panel-1'>Geschäftsmodelle</a>
          <input type='radio' name='radio-set' id='st-control-2' />
          <a href='#st-panel-2'>Agiles Training</a>
          <input type='radio' name='radio-set' id='st-control-3' />
          <a href='#st-panel-3'>Zukunft</a>
          <input type='radio' name='radio-set' id='st-control-4' />
          <a href='#st-panel-4'>Blockchain</a>
          <input type='radio' name='radio-set' id='st-control-5' />
          <a href='#st-panel-5'>Künstliche Intelligenz</a>

          <div className='st-scroll'>
            <section className='st-panel flex-column' id='st-panel-1'>
              {/* <div className='st-deco' data-icon='1'></div> */}
              <Stage1 />
            </section>

            <section className='st-panel st-color' id='st-panel-2'>
              {/* <div className='st-deco' data-icon='2'></div> */}
              <Stage2 />
            </section>

            <section className='st-panel' id='st-panel-3'>
              {/* <div className='st-deco' data-icon='3'></div> */}
              <Stage3 />
            </section>

            <section className='st-panel st-color' id='st-panel-4'>
              {/* <div className='st-deco' data-icon='4.1'></div> */}
              <Stage41 />
            </section>

            <section className='st-panel' id='st-panel-5'>
              {/* <div className='st-deco' data-icon='4.2'></div> */}
              <Stage42 />
            </section>
          </div>
        </div>
        <Stage3Modal
          name='Genossenschaft'
          modalID='genossenschaft'
          src='/videos/genossenschaft.mp4'
          poster='/videos/genossenschaft-poster.jpg'
        />
        <Stage3Modal
          name='Blockchain'
          modalID='blockchain'
          src='/videos/blockchain.mp4'
          poster='/videos/blockchain-poster.jpg'
        />
        <Stage3Modal
          name='Fintech'
          modalID='fintech'
          src='/videos/fintech.mp4'
          poster='/videos/fintech-poster.jpg'
        />
      </>
    )
  }
}

export default Workshop
