import React from 'react'

export default function Stage4_1() {
  return (
    <div
      className='container position-relative'
      style={{ top: '15px', marginBottom: '5em' }}>
      <h1 className='font-weight-bold station-1 text-vb-orange header-1'>
        Station 4.1:
        <br />
        Aspekte der Digitalisierung erleben
      </h1>
      <div className='mt-3 flex-column header-2'>
        <strong>Zwei Aspekte der Digitalisierung:</strong>
        <span>Wie erklärt man Blockchain?</span>
      </div>

      <div className='row mt-5'>
        <div className='col-md-8 col-sm-12'>
          <div className='card h-100 gradient text-white'>
            <div className='card-body'>
              <div
                className='jumbotron my-0 py-0'
                style={{ background: 'none' }}>
                <h1 className='display-4 underline'>
                  Blockchain mit Post-its erlebbar machen
                </h1>
                <div className='flex-row flex-center'>
                  <i className='fas fa-bullseye fa-5x mr-5'></i>

                  <p className='lead text-white my-5'>
                    Verständnis für die grundsätzliche Funktionsweise einer
                    Blockchain und die damit einhergehenden Unterschiede zu
                    klassischen Transaktionen. Darauf aufbauend werden neue
                    Geschäftspotenziale aber auch Risiken für Banken deutlich.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4 col-sm-12 mt-sm-5 mt-md-0 order-sm-last order-md-0'>
          <div className='card-download p-1'>
            <a
              href='https://medium.com/@ericmorrow/blockchain-the-simulation-f6fab67392ff'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='card-download-image'>
                <img
                  className='img-fluid card-download-image-single'
                  src={'/images/workshop/PostIts-poster.jpg'}
                  alt=''
                />
              </div>
            </a>
            <div className='card-download-content text-black'>
              <i className='fas fa-file-signature'></i>
              Öffnen Sie hier die Blockchain Übung.
            </div>
          </div>
        </div>

        <div className='col mt-5 mb-md-5'>
          <div className='card erkenntnis'>
            <div className='card-body'>
              <h4 className='card-title text-center display-4 mt-0'>
                <i className='fas fa-lightbulb text-vb-orange'></i>
              </h4>
              <ul
                className='list-group list-group-flush'
                style={{ lineHeight: 2, fontSize: '18px' }}>
                <li className='list-group-item bg-none'>
                  Was ist eine Blockchain:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Eine Kette von „Informationsschnipseln“ auf der
                      Transaktionsdaten hinterlegt sind.
                    </li>
                    <li className='list-group-item bg-none'>
                      Blockchain-Nutzer bilden ein loses und dezentrales
                      Netzwerk von Transaktionspartnern, die überall verteilt
                      sein können.
                    </li>
                    <li className='list-group-item bg-none'>
                      Es gibt keine zentrale Instanz zur Kontrolle der
                      Transaktionen.
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Genesis-Block:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Der erste Block der Kette.
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Konsens-Prinzip oder „50+1 Regel“:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Durch die „50+1 Regel“ muss die Mehrheit der Teilnehmer
                      bei „Berechnungen“ von Transaktionen zu einem Konsens
                      kommen.
                    </li>
                    <li className='list-group-item bg-none'>
                      Blockchains gelten wegen dem Konsens-Prinzip praktisch als
                      unhackbar.
                    </li>
                  </ul>
                </li>
                <li className='list-group-item bg-none'>
                  Mining:
                  <ul
                    className='list-group list-group-flush'
                    style={{ lineHeight: 2, fontSize: '18px' }}>
                    <li className='list-group-item bg-none'>
                      Blockchain Berechnungen benötigen große IT-Rechenleistung.
                    </li>
                    <li className='list-group-item bg-none'>
                      Als Anreiz wird Personen, die Computerleistung zur
                      Verfügung stellen, pro Berechnung Kryptowährung
                      gutgeschrieben.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
